import React from "react";
import Loadable from "react-loadable";
import { Spinner } from "react-bootstrap";

export const LoadableContainer = (options) => Loadable({
  loading: () => (
      <div className='h-100 w-100 flex-fill d-flex align-items-center justify-content-center'>
        <Spinner size='lg' variant='danger' animation='grow' />
      </div>
  ),
  delay: 200,
  timeout: 10,
  ...options
});

export default LoadableContainer;
