import React, { useState } from 'react';
import { Button, Col, Form, Alert } from 'react-bootstrap';

import { withFirebase } from '../../../firebase';

import { states } from '../../../util';

import './auth-modal.scss';

const SignUpForm = ({ firebase, onSubmit, onHide }) => {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [error, setError] = useState(null);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = event => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    setError(null);

    if (form.checkValidity() === false) {
      return;
    }

    if (password !== passwordConfirmation) {
      setPasswordError(true);

      return;
    }

    setLoading(true);
    firebase.doCreateUserWithEmailAndPassword(email, password, { firstName, lastName, address, city, zip, state })
      .then(user => onSubmit(user))
      .catch(error => setError(error.message))
      .finally(() => setLoading(false));
  };

  return (
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className='required' controlId='formBasicEmail'>
          <Form.Label>Email address</Form.Label>
          <Form.Control
              required
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={e => setEmail(e.target.value)}/>
          <Form.Text className='text-muted'>
            We'll never share your email with anyone else
          </Form.Text>
        </Form.Group>
        <Form.Group className='required' controlId='formBasicPassword'>
          <Form.Label>Password</Form.Label>
          <Form.Control
              required
              minLength={6}
              type='password'
              placeholder='Password'
              value={password}
              onChange={e => {
                setPasswordError(false);
                setPassword(e.target.value);
              }}/>
        </Form.Group>
        <Form.Group className='required' controlId='formBasicPasswordConfirmation'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
              required
              minLength={6}
              type='password'
              placeholder='Confirm password'
              value={passwordConfirmation}
              onChange={e => {
                setPasswordError(false);
                setPasswordConfirmation(e.target.value);
              }}/>
        </Form.Group>
        {passwordError && <Alert variant='danger'>Password doesn't match</Alert>}
        <Form.Row>
          <Form.Group as={Col} controlId='formBasicFirstName'>
            <Form.Label>First Name</Form.Label>
            <Form.Control
                type='text'
                placeholder='Enter first name'
                value={firstName}
                onChange={e => setFirstName(e.target.value)}/>
          </Form.Group>
          <Form.Group as={Col} controlId='formBasicLastName'>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
                type='text'
                placeholder='Enter last name'
                value={lastName}
                onChange={e => setLastName(e.target.value)}/>
          </Form.Group>
        </Form.Row>
        <Form.Group controlId='formGridAddress1'>
          <Form.Label>Shipping address</Form.Label>
          <Form.Control
              type='text'
              placeholder='1234 Main St'
              value={address}
              onChange={e => setAddress(e.target.value)}/>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId='formGridCity'>
            <Form.Label>City</Form.Label>
            <Form.Control
                type='text'
                placeholder='City'
                value={city}
                onChange={e => setCity(e.target.value)}/>
          </Form.Group>
          <Form.Group as={Col} controlId='formGridState'>
            <Form.Label>State</Form.Label>
            <Form.Control as='select' value={state} onChange={e => setState(e.target.value)}>
              <option value={null}>Choose...</option>
              {states.map(state => <option key={state.abbreviation} value={state.name}>{state.name}</option>)}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId='formGridZip'>
            <Form.Label>Zip</Form.Label>
            <Form.Control
                type='text'
                placeholder='61001'
                value={zip}
                onChange={e => setZip(e.target.value)}/>
          </Form.Group>
        </Form.Row>
        {error && <Alert variant='danger'>{error}</Alert>}
        <div className='d-flex justify-content-between'>
          <Button variant='light' type='submit' disabled={loading} onClick={onHide}>
            <b>CANCEL</b>
          </Button>
          <Button variant='danger' type='submit' disabled={loading}>
            <b>SIGN UP</b>
          </Button>
        </div>
      </Form>
  );
};

export default withFirebase(SignUpForm);
