import React, { Fragment, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import AppHeader from './components/app-header';
import AppFooter from './components/AppFooter';
import Loadable from './components/loadable/Loadable';

import { withFirebase } from './firebase';
import { setCurrentUser } from './actions/userActions';
import { setProducts, setRatioOptions, setMaterialOptions, setMaterial, setRatio } from './actions';
import { apiUrl } from './config';
import { sortByField } from './util';

const HomePage = Loadable({
  loader: () => import('./pages/home/HomePage')
});

const MapFlowPage = Loadable({
  loader: () => import('./pages/map-flow/MapFlowPage')
});

const MyOrdersPage = Loadable({
  loader: () => import('./pages/orders/MyOrdersPage')
});

const TrackOrderPage = Loadable({
    loader: () => import('./pages/orders/TrackOrderPage')
});

const SuccessPage = Loadable({
    loader: () => import('./pages/map-flow/SuccessPage')
});

const AdminPage = Loadable({
    loader: () => import('./pages/admin/AdminPage')
});

const AdminUsersPage = Loadable({
    loader: () => import('./pages/admin/AdminUsersPage')
});

const AdminOrdersPage = Loadable({
    loader: () => import('./pages/admin/AdminOrdersPage')
});

const MyProfilePage = Loadable({
    loader: () => import('./pages/profile/MyProfilePage')
});

const PricingPage = Loadable({
    loader: () => import('./pages/pricing/PricingPage')
});

const HowItWorksPage = Loadable({
    loader: () => import('./pages/static/HowItWorksPage')
});

const FaqPage = Loadable({
    loader: () => import('./pages/static/FaqPage')
});

const ContactPage = Loadable({
    loader: () => import('./pages/static/ContactPage')
});

const App = ({ currentUser, isUserLoaded, firebase, setUser, setProducts, setRatioOptions, setMaterialOptions, setMaterial, setRatio }) => {
  useEffect(() => {
    firebase.doGetCurrentUser()
      .then(res => setUser(res));

    fetchProducts().then(res => {
        setProducts(res.products);
        setRatioOptions(res.ratioOptions);
        setMaterialOptions(res.materialOptions);
        setMaterial(res.materialOptions[0]);
        setRatio(res.ratioOptions[0]);
    });
  }, []);

  if (!isUserLoaded) {
      return (
          <div className='h-100 w-100 flex-fill d-flex align-items-center justify-content-center'>
              <Spinner size='lg' variant='danger' animation='grow' />
          </div>
      );
  }
  
  return (
    <Fragment>
        <div className='d-flex flex-fill flex-column bg-light'>
            <AppHeader/>
            <Switch>
                <Route exact path='/' component={HomePage} />
                <Route exact path='/map' component={MapFlowPage} />
                <Route exact path='/success' component={SuccessPage} />
                <Route exact path='/track-order' component={TrackOrderPage}/>
                <Route exact path='/pricing' component={PricingPage}/>
                <Route exact path='/how-it-works' component={HowItWorksPage}/>
                <Route exact path='/faq' component={FaqPage}/>
                <Route exact path='/contact' component={ContactPage}/>
                {
                    isUserLoaded && currentUser && (
                        <Route exact path='/orders' component={MyOrdersPage}/>
                    )
                }
                {
                    isUserLoaded && currentUser && (
                        <Route exact path='/profile' component={MyProfilePage}/>
                    )
                }
                {
                    isUserLoaded && currentUser && currentUser.admin && (
                        <AdminPage>
                            <Route exact path='/admin/users' component={AdminUsersPage}/>
                            <Route exact path='/admin/orders' component={AdminOrdersPage}/>
                        </AdminPage>
                    )
                }
                <Redirect from='*' to='/'/>
            </Switch>
            <AppFooter/>
        </div>
    </Fragment>
  );
};

const fetchProducts = () => {
    return fetch(`${apiUrl}fetchProductsAndSKUs`)
        .then(res => res.json())
        .then(res => JSON.parse(res.body).products)
        .then(products => {
            const ratioOptions = products.map((product, index) => ({
                value: index,
                label: product.name,
                aspectRatio: parseInt(product.metadata.width) / parseInt(product.metadata.height),
                product: product.id,
                width: product.metadata.width,
                height: product.metadata.height
            })).sort((a, b) => sortByField(a, b, 'height'));

            const materialOptions = Array.from(new Set(products.map(product => product.skus).flat().map(sku => sku.attributes.name)))
                .sort()
                .map((material, index) => ({ value: index, label: material }));

            return { products, ratioOptions, materialOptions };
        });
};

const mapStateToProps = ({ userReducer }) => {
    return {
        currentUser: userReducer.currentUser,
        isUserLoaded: userReducer.isLoaded
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: user => dispatch(setCurrentUser(user)),
        setProducts: products => dispatch(setProducts(products)),
        setMaterialOptions: products => dispatch(setMaterialOptions(products)),
        setRatioOptions: products => dispatch(setRatioOptions(products)),
        setMaterial: material => dispatch(setMaterial(material)),
        setRatio: ratio => dispatch(setRatio(ratio))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(App));