import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';

import { withFirebase } from '../../../firebase';

import './auth-modal.scss';

const SignInForm = ({ firebase, onSubmit, onHide, onResetPassword }) => {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = event => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    if (form.checkValidity() === false) {
      return;
    }

    setLoading(true);
    firebase.doSignInWithEmailAndPassword(email, password)
      .then(user => onSubmit(user))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  return (
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId='formBasicEmail'>
          <Form.Label>Email address</Form.Label>
          <Form.Control
              required
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={e => {
                setError(false);
                setEmail(e.target.value);
              }}/>
        </Form.Group>
        <Form.Group controlId='formBasicPassword'>
          <Form.Label>Password</Form.Label>
          <Form.Control
              required
              minLength={6}
              type='password'
              placeholder='Password'
              value={password}
              onChange={e => {
                setError(false);
                setPassword(e.target.value);
              }}/>
        </Form.Group>
        { error && <Alert variant='danger'>Invalid email or password</Alert> }
        <div className='d-flex justify-content-between'>
          <Button variant='light' type='submit' disabled={loading} onClick={onHide}>
            <b>CANCEL</b>
          </Button>
          <Button variant='danger' type='submit' disabled={loading}>
            <b>SIGN IN</b>
          </Button>
        </div>
        <div className='d-flex justify-content-center'>
          <div className='auth-link' onClick={onResetPassword}>
            Reset Password
          </div>
        </div>
      </Form>
  );
};

export default withFirebase(SignInForm);
