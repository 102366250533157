import {
  SET_ADDRESS,
  SET_CENTER,
  SET_MAP_TYPE,
  SET_STATIC_MAP,
  SET_RATIO,
  SET_ZOOM,
  SET_CROPPED_IMAGE,
  SET_CUSTOMIZED_IMAGE,
  SET_MATERIAL,
  SET_CURRENT_USER,
  SET_PRODUCTS,
  SET_MATERIAL_OPTIONS,
  SET_RATIO_OPTIONS
} from '../actions/actionTypes';

const initialState = {
  currentUser: null,
  address: '',
  center: { lat: 0, lng: 0 },
  mapType: 'satellite',
  ratio: null,
  zoom: 16,
  staticMapUrl: null,
  croppedImage: null,
  customizedImage: null,
  material: null,
  products: [],
  materialsOptions: [],
  ratioOptions: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, currentUser: action.value };
    case SET_ADDRESS:
      return { ...state, address: action.value };
    case SET_CENTER:
      return { ...state, center: action.value };
    case SET_MAP_TYPE:
      return { ...state, mapType: action.value };
    case SET_STATIC_MAP:
      return { ...state, staticMapUrl: action.value };
    case SET_RATIO:
      return { ...state, ratio: action.value };
    case SET_ZOOM:
      return { ...state, zoom: action.value };
    case SET_CROPPED_IMAGE:
      return { ...state, croppedImage: action.value };
    case SET_CUSTOMIZED_IMAGE:
      return { ...state, customizedImage: action.value };
    case SET_MATERIAL:
      return { ...state, material: action.value };
    case SET_PRODUCTS:
      return { ...state, products: action.value };
    case SET_MATERIAL_OPTIONS:
      return { ...state, materialsOptions: action.value };
    case SET_RATIO_OPTIONS:
      return { ...state, ratioOptions: action.value };
    default:
      return state;
  }
};

export default reducer;