const firebaseConfig = {
  apiKey: "AIzaSyDkSRxAmrEZNr8OGJmxx97FhfBIxl1uKfs",
  authDomain: "supreme-graphics.firebaseapp.com",
  databaseURL: "https://supreme-graphics.firebaseio.com",
  projectId: "supreme-graphics",
  storageBucket: "supreme-graphics.appspot.com",
  messagingSenderId: "175432126093",
  appId: "1:175432126093:web:b99ca7fd6d4b4e08ee1cc4",
  measurementId: "G-017H1PDC8Y"
};

export default firebaseConfig;