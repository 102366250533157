import {
  SET_ADDRESS,
  SET_CENTER,
  SET_MAP_TYPE,
  SET_STATIC_MAP,
  SET_RATIO,
  SET_ZOOM,
  SET_CROPPED_IMAGE,
  SET_CUSTOMIZED_IMAGE,
  SET_MATERIAL,
  SET_PRODUCTS,
  SET_MATERIAL_OPTIONS,
  SET_RATIO_OPTIONS
} from './actionTypes';

export const setAddress = (value) => ({ type: SET_ADDRESS, value });

export const setCenter = (value) => ({ type: SET_CENTER, value });

export const setMapType = (value) => ({ type: SET_MAP_TYPE, value });

export const setStaticMap = (value) => ({ type: SET_STATIC_MAP, value });

export const setRatio = (value) => ({ type: SET_RATIO, value });

export const setZoom = (value) => ({ type: SET_ZOOM, value });

export const setCroppedImage = (value) => ({ type: SET_CROPPED_IMAGE, value });

export const setCustomizedImage = (value) => ({ type: SET_CUSTOMIZED_IMAGE, value });

export const setMaterial = (value) => ({ type: SET_MATERIAL, value });

export const setProducts = (value) => ({ type: SET_PRODUCTS, value });

export const setMaterialOptions = (value) => ({ type: SET_MATERIAL_OPTIONS, value });
export const setRatioOptions = (value) => ({ type: SET_RATIO_OPTIONS, value });