import React from 'react';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';

class AppFooter extends React.Component {
    render() {
        return (
            <div className='p-2 border-top text-white shadow d-flex justify-content-center'
                 style={{ zIndex: '1000', fontSize: '12px', backgroundColor: '#bd2130' }}>
                <div className='mr-4'>© Copyright {moment().year()} Big Land Prints - All rights reserved.</div>
                <div className='d-flex flex-wrap'>
                    <LinkContainer to='/' className='mr-2 cursor-pointer text-underline'>
                        <span>Get started</span>
                    </LinkContainer>
                    <LinkContainer to='/how-it-works' className='mr-2 cursor-pointer text-underline'>
                        <span>How it works</span>
                    </LinkContainer>
                    <LinkContainer to='/pricing' className='mr-2 cursor-pointer text-underline'>
                        <span>Pricing</span>
                    </LinkContainer>
                    <LinkContainer to='/faq' className='mr-2 cursor-pointer text-underline'>
                        <span>FAQs</span>
                    </LinkContainer>
                    <LinkContainer to='/contact' className='cursor-pointer text-underline'>
                        <span>Contact Us</span>
                    </LinkContainer>
                </div>
            </div>
        );
    }
}

export default AppFooter;