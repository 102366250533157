const deliveryStatuses = {
  processing: "Processing",
  shipped: "Shipped"
};

const paymentStatuses = { all: 'all', paid: 'paid', unpaid: 'unpaid' };

const deliveryStatusVariants = {
    [deliveryStatuses.processing]: 'light',
    [deliveryStatuses.shipped]: 'warning'
};

export default { deliveryStatuses, paymentStatuses, deliveryStatusVariants };