import moment from 'moment';

import * as mapUtils from './mapUtils';
import states from './states';
import statuses from './statuses';

const getCurrentDomain = () => `${window.location.protocol}//${window.location.hostname}${window.location.port && ':' + window.location.port}`;

const price = (amount) => `$${amount/100}`;

const sortByField = (a, b, field, order = 'desc', toNumber = null) => {
    const result = order === 'desc' ? 1 : -1;

    if (!a[field]) {
        return result;
    }

    if (!b[field]) {
        return -result;
    }

    a = toNumber ? toNumber(a[field]) : a[field];
    b = toNumber ? toNumber(b[field]) : b[field];

    if (a < b){
        return result;
    }
    if (a > b){
        return -result;
    }
    return 0;
};

const sortOrdersByCreatedAt = (orders) => orders.sort((a, b) => sortByField(a, b, 'createdAt', 'desc', val => moment(val, dbDateFormat).unix()));

const dbDateFormat = 'YYYY-MM-DD';
const displayDateFormat = 'MMM D, YYYY';

const displayDate = (dateString) => dateString ? moment(dateString, dbDateFormat).format(displayDateFormat) : '';

const redirectToCheckout = (stripe, orderId, skuId, email) => stripe.redirectToCheckout({
    items: [
        { sku: skuId, quantity: 1 }
    ],
    clientReferenceId: orderId,
    customerEmail: email,
    successUrl: `${getCurrentDomain()}/success?orderId=${orderId}`,
    cancelUrl: `${getCurrentDomain()}/track-order?orderId=${orderId}`
});

const prepareImageForDownload = (image, reorder) => !reorder ? image.substr(22) : image.substr(37);

const ROLES = {
    ADMIN: 'admin'
};

function mergeArrayObjectsByField(field, arrays) {
    let result = arrays[0];

    for (let i = 1; i < arrays.length; i++) {
        result = result.map(item1 => ({ ...item1, ...arrays[i].find(item2 => item2[field] === item1[field]) }));
    }

    return result;
}

const paymentFilters = { all: 'all', ...statuses.paymentStatuses };
const shipmentFilters = { all: 'all', ...statuses.deliveryStatuses };

function paymentFilterVariant(filter, paymentFilter) {
    return paymentFilters[filter] === paymentFilter ? 'danger' : 'light';
}

function shipmentFilterVariant(filter, shipmentFilter) {
    return shipmentFilters[filter] === shipmentFilter ? 'danger' : 'light';
}

function filterOrders(orders, paymentFilter, shipmentFilter) {
    switch (paymentFilter) {
        case paymentFilters.paid:
            orders = orders.filter(order => order.paid);
            break;
        case paymentFilters.unpaid:
            orders = orders.filter(order => !order.paid);
            break;
        default:
            break;
    }

    if (shipmentFilter !== 'all') {
        orders = orders.filter(order => order.deliveryStatus === shipmentFilter);
    }

    return orders;
}

function searchOrders(searchString, orders) {
    searchString = searchString.toLowerCase();

    return orders.filter(order => {
        const id = order.id.toLowerCase();
        const firstName = order.firstName.toLowerCase();
        const lastName = order.lastName.toLowerCase();
        const email = order.email.toLowerCase();
        const trackingNumber = order.trackingNumber ? order.trackingNumber.toLowerCase() : '';

        return firstName.indexOf(searchString) > -1 ||
            lastName.indexOf(searchString) > -1 ||
            email.indexOf(searchString) > -1 ||
            trackingNumber.indexOf(searchString) > -1 ||
            id.indexOf(searchString) > -1;
    });
}

const isSmallScreen = () => window.innerWidth < 768;

const createMarkup = text => ({ __html: text });

export {
    mapUtils,
    states,
    statuses,
    getCurrentDomain,
    price,
    sortByField,
    dbDateFormat,
    displayDateFormat,
    displayDate,
    redirectToCheckout,
    prepareImageForDownload,
    mergeArrayObjectsByField,
    ROLES,
    shipmentFilters,
    paymentFilters,
    paymentFilterVariant,
    shipmentFilterVariant,
    filterOrders,
    searchOrders,
    sortOrdersByCreatedAt,
    isSmallScreen,
    createMarkup
};