import { googleApiKey } from "../config";

const googleStaticMapUrl = "https://maps.googleapis.com/maps/api/staticmap";

const defaultRatio = { value: 0, label: "16 : 9", width: 640, height: 360, aspectRatio: 16 / 9 };

const getStaticMap = ({lat, lng}, width, height, zoom, mapType) => {
  return `${googleStaticMapUrl}?center=${lat},${lng}&zoom=${zoom}&size=${width}x${height}&scale=2&maptype=${mapType}&key=${googleApiKey}`;
};

export { getStaticMap, defaultRatio };
