import React, { useState } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';

import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import ResetPasswordForm from './ResetPasswordForm';

import './auth-modal.scss';
import { setCurrentUser } from '../../../actions/userActions';

const AuthModal = ({ show, onHide, setUser }) => {
  const [key, setKey] = useState('signIn');
  const [resetPassword, setResetPassword] = useState(false);

  const handleAuth = (res) => {
    setUser(res);
    onHide();
  };

  return <Modal show={show} onHide={onHide} className='auth-modal'>
    <Modal.Body>
      {
        !resetPassword && (
            <Tabs id='controlled-tab-example' activeKey={key} onSelect={k => setKey(k)}>
              <Tab eventKey='signIn' title='Sign In'>
                <SignInForm onSubmit={handleAuth} onHide={onHide} onResetPassword={() => setResetPassword(true)} />
              </Tab>
              <Tab eventKey='signUp' title='Sign Up'>
                <SignUpForm onSubmit={handleAuth} onHide={onHide} />
              </Tab>
            </Tabs>
        )
      }

      {
        resetPassword && (
          <ResetPasswordForm onHide={() => setResetPassword(false)}/>
        )
      }
    </Modal.Body>
  </Modal>;
};

const mapStateToProps = ({ userReducer }) => {
  return {
    currentUser: userReducer.currentUser,
    isLoaded: userReducer.isLoaded
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: user => dispatch(setCurrentUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);