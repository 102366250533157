import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';

import { withFirebase } from '../../../firebase';

import './auth-modal.scss';

const ResetPasswordForm = ({ firebase, onHide }) => {
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = event => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        setValidated(true);

        if (form.checkValidity() === false) {
            return;
        }

        setLoading(true);

        firebase.doPasswordReset(email)
            .then(() => onHide())
            .catch(error => setError(error.message))
            .finally(() => setLoading(false));
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <h3 className='mb-3'>Reset Password</h3>
            <Form.Group controlId='formBasicEmail'>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                    required
                    type='email'
                    placeholder='Enter email'
                    value={email}
                    onChange={e => {
                        setError(false);
                        setEmail(e.target.value);
                    }}
                />
            </Form.Group>
            { error && <Alert variant='danger'>{error}</Alert> }
            <div className='d-flex justify-content-between'>
                <Button variant='light' type='submit' disabled={loading} onClick={onHide}>
                    <b>CANCEL</b>
                </Button>
                <Button variant='danger' type='submit' disabled={loading}>
                    <b>RESET</b>
                </Button>
            </div>
        </Form>
    );
};

export default withFirebase(ResetPasswordForm);
