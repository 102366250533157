import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import { routerReducer as routing } from "react-router-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducer as form } from 'redux-form';
import { StripeProvider } from "react-stripe-elements";

import reducer from "./reducers";
import userReducer from "./reducers/user";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import Firebase, { FirebaseContext } from './firebase';

import { stripePublicApiKey } from "./config";

import "bootstrap/dist/css/bootstrap.min.css";
import 'tui-image-editor/dist/tui-image-editor.css'

import "./index.scss"

const combinedReducers = combineReducers({ reducer, userReducer, routing, form });
const store = createStore(combinedReducers, composeWithDevTools(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <StripeProvider apiKey={stripePublicApiKey}>
          <BrowserRouter>
            <Route component={App} />
          </BrowserRouter>
      </StripeProvider>
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

window.oncontextmenu = e => e.preventDefault();