import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import AuthModal from '../modals/auth/AuthModal';

import './app-header.scss'

import { withFirebase } from '../../firebase';
import { ROLES } from '../../util';

import { signOut } from '../../actions/userActions';
import RoleAware from '../RoleAware';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";

const AppHeader = ({ firebase, currentUser, isLoaded, signOut }) => {
    const [show, setShow] = useState(false);
    const [logoUrl, setLogoUrl] = useState(null);

    const handleSignOut = () => {
        firebase.doSignOut().then(() => signOut());
    };

    useEffect(() => {
        firebase.fetchLogo().then(logos => setLogoUrl(logos[0]));
    }, []);

    return (
        <Navbar collapseOnSelect bg='white' expand='md' className='app-header shadow'>
            <LinkContainer to='/'>
                <Navbar.Brand className='p-0'><img src={logoUrl || 'BLP-logo.png'} alt='logo' height='44px'/></Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls='basic-navbar-nav'/>
            <Navbar.Collapse id='basic-navbar-nav' className='flex-row-reverse'>
                <Nav>
                    <Nav.Link>
                        <a href='tel:800.356.3320'>
                            <FontAwesomeIcon icon={faPhone} className='mr-2'/>
                            800-356-3320
                        </a>
                    </Nav.Link>
                    {
                        isLoaded && currentUser && (
                            <RoleAware roles={[ROLES.ADMIN]}>
                                <LinkContainer to='/admin/users'>
                                    <Nav.Link>ADMIN</Nav.Link>
                                </LinkContainer>
                            </RoleAware>
                        )
                    }
                    <LinkContainer to='/track-order'>
                        <Nav.Link>TRACK ORDER</Nav.Link>
                    </LinkContainer>
                    {
                        isLoaded && currentUser && (
                            <LinkContainer to='/orders'>
                                <Nav.Link>MY ORDERS</Nav.Link>
                            </LinkContainer>
                        )
                    }
                    {
                        isLoaded && currentUser && (
                            <LinkContainer to='/profile'>
                                <Nav.Link>MY PROFILE</Nav.Link>
                            </LinkContainer>
                        )
                    }
                    {
                        isLoaded && (
                            !currentUser ? (
                                <Button className='ml-2' variant='success' onClick={() => setShow(true)}>
                                    <b>SIGN IN</b>
                                </Button>
                            ) : (
                                <Button className='ml-2' variant='outline-danger' size='sm' onClick={handleSignOut}>
                                    <b>SIGN OUT</b>
                                </Button>
                            )
                        )
                    }
                </Nav>
            </Navbar.Collapse>
            <AuthModal show={show} onHide={() => setShow(false)}/>
        </Navbar>
    );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    currentUser: userReducer.currentUser,
    isLoaded: userReducer.isLoaded
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(AppHeader));