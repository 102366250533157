import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import moment from 'moment';

import { firebaseConfig } from '../config';

import { dbDateFormat, mergeArrayObjectsByField, statuses } from '../util';

import { ORDERS_COLLECTION, USER_DATA_COLLECTION, USER_ROLES_COLLECTION, STATIC_TEXT_COLLECTION } from './collections';

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  doCreateUserWithEmailAndPassword = (email, password, userData) => {
    return this.auth.createUserWithEmailAndPassword(email, password)
      .then(({ user }) => {
        this.saveUserData(userData);

        if (user) {
          return this.doGetCurrentUser();
        }
      });
  };

  doSignInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password)
      .then(auth => {
        if (auth.user) {
          return this.doGetCurrentUser();
        }
      });
  };

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  saveUserData = userData =>
    this.db.collection(USER_DATA_COLLECTION).doc(this.auth.currentUser.uid).set(userData)
      .then(() => this.auth.currentUser.updateProfile({ displayName: `${userData.firstName} ${userData.lastName}` }));

  doGetCurrentUser = () => new Promise((resolve, reject) => {
    const unsubscribe = this.auth.onAuthStateChanged(user => {
      if (user) {
        Promise.all([this.getCurrentUserData(), this.getCurrentUserRoles()])
            .then(responses => resolve({...user, ...responses[0], ...responses[1]}))
      } else {
        resolve(user);
      }
      unsubscribe();
    }, reject);
  }).catch(() => null);

  getCurrentUserData = () => {
    return this.db.collection(USER_DATA_COLLECTION).doc(this.auth.currentUser.uid).get()
      .then(doc => doc.data())
      .catch(error => console.log(error));
  };

  getCurrentUserRoles = () =>
      this.db.collection(USER_ROLES_COLLECTION).doc(this.auth.currentUser.uid).get()
          .then(doc => doc.data())
          .catch(() => {});

  getOrders = () => {
    return this.db.collection(ORDERS_COLLECTION).where('uid', '==', this.auth.currentUser.uid).get()
      .then(res => res.docs.map(doc => doc.data()))
      .catch(error => console.log(error));
  };

  getUserData = uid =>
    this.db.collection(USER_DATA_COLLECTION).doc(uid).get()
      .then(doc => doc.data())
      .catch(() => {
      });

  fetchAllUsers = () => Promise.all([this.fetchUsers(), this.fetchUserRoles()])
          .then(responses => mergeArrayObjectsByField('uid', responses));

  fetchUsers = () => this.db.collection(USER_DATA_COLLECTION)
      .get()
      .then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));

  fetchUserRoles = () => this.db.collection(USER_ROLES_COLLECTION)
      .get()
      .then(snapshot => snapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() })));

  createOrder = (orderData, image) => {
    const userId = this.auth.currentUser ? this.auth.currentUser.uid : null;
    const order = { uid: userId, ...orderData };
    const storageRef = this.storage.ref();

    return this.db.collection(ORDERS_COLLECTION).add(order).then(doc => {
      return storageRef.child(this.orderImageId(doc.id)).putString(image, 'base64').then((snapshot) => {
        console.log(snapshot);
      }).then(() => doc.id);
    });
  };

  changeDeliveryStatus = (orderId, deliveryStatus) => {
    const data = { deliveryStatus };
    
    if (deliveryStatus === statuses.deliveryStatuses.shipped) {
      data.shippingDate = moment(new Date()).format(dbDateFormat);
    }

    if (deliveryStatus === statuses.deliveryStatuses.delivered) {
      data.deliveryDate = moment(new Date()).format(dbDateFormat);
    }
    
    return this.db.collection(ORDERS_COLLECTION).doc(orderId).update(data).catch(err => {});
  };

  makeShipped = (orderId, trackingNumber) => {
    const data = {
      deliveryStatus: statuses.deliveryStatuses.shipped,
      shippingDate: moment(new Date()).format(dbDateFormat),
      trackingNumber
    };

    return this.db.collection(ORDERS_COLLECTION).doc(orderId).update(data).catch(err => {});
  };

  fetchOrders = (all = false, email = this.auth.currentUser.email) => {
    const storageRef = this.storage.ref();
    let doc = this.db.collection(ORDERS_COLLECTION);

    if (!all) {
      doc = doc.where('email', '==', email);
    }

    return doc.get()
        .then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        .then(orders => {
          const PROMISES = orders.map(order => storageRef.child(this.orderImageId(order.id)).getDownloadURL());

          return Promise.all(PROMISES).then(urls => urls.map((imageUrl, index) => ({ ...orders[index], imageUrl })));
        })
        .catch(() => []);
  };

  fetchOrderById = (orderId) => {
    const storageRef = this.storage.ref();
    const PROMISES = [
      this.db.collection(ORDERS_COLLECTION).doc(orderId).get().then(doc => doc.data()),
      storageRef.child(this.orderImageId(orderId)).getDownloadURL()
    ];

    return Promise.all(PROMISES)
        .then(([order, imageUrl]) => ({ ...order, imageUrl, id: orderId }))
        .catch(err => null);
  };

  orderImageId = (orderId) => `image_${orderId}`;

  downloadImageContent = (orderId) => new Promise((resolve, reject) => {
      const storageRef = this.storage.ref();

      storageRef.child(this.orderImageId(orderId)).getDownloadURL().then(url => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = event => {
          const blob = xhr.response;
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => resolve(reader.result);
        };
        xhr.open('GET', url);
        xhr.send();
      }).catch((error) => {
        reject(error);
      });
  });

  switchRole = (uid, role) => {
      const doc = this.db.collection(USER_ROLES_COLLECTION).doc(uid);

      return doc.get().then(doc => doc.data())
          .then(data => ({ ...data, [role]: data ? !data[role] : true }))
          .then(data => doc.set(data));
  };

  fetchStaticImages = () => {
    const storageRef = this.storage.ref();

    return storageRef.child('static').listAll()
        .then(res => res.items.map(item => item.getDownloadURL()))
        .then(promises => Promise.all(promises));
  };

  fetchStaticText = docId => this.db.collection(STATIC_TEXT_COLLECTION).doc(docId).get()
      .then(doc => doc.data())
      .catch(() => {});

  fetchLogo = () => {
    const storageRef = this.storage.ref();

    return storageRef.child('logo').listAll()
        .then(res => res.items.map(item => item.getDownloadURL()))
        .then(promises => Promise.all(promises));
  };
}

export default Firebase;
