import { SET_CURRENT_USER, SIGN_OUT } from "../actions/actionTypes";

const initialUserData = {
  email: "",
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  zip: ""
};

const initialState = {
  isLoaded: false,
  currentUser: initialUserData
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, currentUser: action.value, isLoaded: true };
    case SIGN_OUT:
      return { ...state, currentUser: null };
    default:
      return state;
  }
};

export default reducer;
