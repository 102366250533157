// User actions

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SIGN_OUT = 'SIGN_OUT';

// Main flow actions

export const SET_ADDRESS = 'SET_ADDRESS';

export const SET_CENTER = 'SET_CENTER';
export const SET_MAP_TYPE = 'SET_MAP_TYPE';
export const SET_RATIO = 'SET_RATIO';
export const SET_STATIC_MAP = 'SET_STATIC_MAP';
export const SET_ZOOM = 'SET_ZOOM';

export const SET_CROPPED_IMAGE = 'SET_CROPPED_IMAGE';

export const SET_CUSTOMIZED_IMAGE = 'SET_CUSTOMIZED_IMAGE';

export const SET_MATERIAL = 'SET_MATERIAL';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_MATERIAL_OPTIONS = 'SET_MATERIAL_OPTIONS';
export const SET_RATIO_OPTIONS = 'SET_RATIO_OPTIONS';